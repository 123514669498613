.slider__content.slick-initialized {
    -webkit-animation: none;
    animation: none;
    -webkit-transform: transform(translateY(0)) !important;
    -moz-transform: transform(translateY(0)) !important;
    -ms-transform: transform(translateY(0)) !important;
    -o-transform: transform(translateY(0)) !important;
    transform: transform(translateY(0)) !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important
}

.grid {
    display: table;
}

.grid,
.grid:before {
    clear: both;
}

.grid:after {
    content: " ";
}

[class*=col-] {
    float: left;
    clear: none !important;
}

.link--see-more-tip-wrapper {
    width: auto;
    text-align: center;
}

.flex__align-items__center {
    display: table;
    >* {
        float: none;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
    }
}

.modal {
    opacity: 1 !important;
    position: fixed !important;
    top: 50%;
    left: 50%;
    z-index: 100 !important;
    -ms-transform: translateY(-50%) translateX(-50%);
}